var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-span-2 grid grid-cols-[124px,1fr] gap-6 mt-7 mb-7"},[_c('div',{staticClass:"w-[124px] h-[124px] flex items-center justify-center border border-dashed border-gray-500 bg-gray-50 rounded-md p-4"},[_c('img',{class:{
        'object-contain': true,
        'w-[34px] h-[34px]': !_vm.hasLogo
      },attrs:{"src":_vm.logoPreview || require('@/assets/icons/image-placeholder.svg'),"alt":"logo preview","width":"108","height":"108"}})]),_c('div',{staticClass:"w-full flex flex-col"},[_c('p',{staticClass:"font-lato text-blue-gray-800 text-[15px] font-medium leading-6"},[_vm._v(" Logo Layanan ")]),_vm._m(0),_c('ValidationProvider',{ref:"serviceLogo",staticClass:"flex flex-col col-span-2",attrs:{"rules":_vm.hasInitialImage ? 'image|size:1000|maxdimensions:200,200': 'required|image|size:1000|maxdimensions:200,200'},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('div',{staticClass:"flex items-center mb-4"},[_c('label',{class:{
            'w-fit min-h-[38px] flex px-4 py-[10px] rounded-lg bg-green-700 hover:bg-green-600': true,
            'text-white font-bold items-center text-sm cursor-pointer': true,
            'bg-gray-500 hover:bg-gray-500': !_vm.isMasterDataSelected || (_vm.hasLogo && valid) || _vm.hasInitialImage
          },attrs:{"for":"service-logo"}},[_c('span',[_vm._v("Pilih File")]),_c('JdsIcon',{staticClass:"ml-3 h-[14px] w-[14px]",attrs:{"name":"plus","size":"14px","fill":"#fff"}})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasLogo),expression:"!hasLogo"}],staticClass:"ml-3 font-lato text-blue-gray-800 text-sm"},[_vm._v(" Belum ada file terpilih ")])]),_c('input',{attrs:{"id":"service-logo","type":"file","name":"service-logo","hidden":"","accept":"image/png, image/jpeg","disabled":!_vm.isMasterDataSelected || (_vm.hasLogo && valid) || _vm.hasInitialImage},on:{"change":_vm.handlePickLogo}}),_c('span',{staticClass:"font-lato text-[13px] text-red-600 mt-1"},[_vm._v(_vm._s(errors[0]))]),_c('transition',{attrs:{"name":"fade"}},[((_vm.hasLogo && valid) || _vm.hasInitialImage)?_c('div',{staticClass:"w-fit flex border border-green-700 rounded-lg min-h-[38px] items-center px-4"},[_c('p',{staticClass:"text-sm font-lato leading-none"},[_vm._v(" "+_vm._s(_vm.logo.name)+" ")]),_c('button',{staticClass:"w-fit h-[38px] p-2 ml-3",on:{"click":_vm.toggleImagePreview}},[_c('JdsIcon',{attrs:{"name":"eye","size":"18px","fill":"#069550"}})],1),_c('button',{staticClass:"w-fit h-[38px] p-2 ml-3",on:{"click":_vm.handleClearLogo}},[_c('JdsIcon',{attrs:{"name":"times","size":"16px","fill":"#069550"}})],1)]):_vm._e()])]}}])})],1),_c('ImagePreview',{attrs:{"source":_vm.imagePreviewSource,"open":_vm.isImagePreviewOpen},on:{"close":_vm.toggleImagePreview}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"font-lato text-blue-gray-800 text-sm mb-[14px]"},[_vm._v(" Ukuran maksimal 1 Mb, dengan resolusi 200x200 pixels. "),_c('br'),_vm._v(" File yang didukung adalah .jpg dan .png ")])
}]

export { render, staticRenderFns }