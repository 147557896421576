var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"relative w-full min-h-[54px] px-4 py-3 border border-gray-300 rounded-xl"},[_c('div',{staticClass:"flex justify-between"},[_vm._t("header",function(){return [_c('h2',{staticClass:"font-roboto font-medium text-base leading-7 text-blue-gray-800"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]}),_c('button',{staticClass:"w-7 h-7 flex-shrink-0 rounded-full flex items-center justify-center border border-gray-200 bg-gray-100",attrs:{"type":"button"},on:{"click":_vm.toggleOpen}},[_c('JdsIcon',{class:{
          'h-4 text-blue-gray-800 transition-transform ease-in' : true,
          '-rotate-180': _vm.isOpen
        },attrs:{"name":"chevron-down","size":"16px"}})],1)],2),_c('div',{class:{
      'w-full transition-all ease-in-out duration-500 overflow-hidden': true,
      'max-h-0': !_vm.isOpen,
      'max-h-[99999999px]': _vm.isOpen
    }},[_c('div',{staticClass:"pt-6"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }